.tenant-selection-container {
    height: 100vh;
    width: 100vw;
    background-image: url('../../public/background.webp'); /* Adjust based on your image location */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  