.range-low {
  background-color: rgb(245, 149, 100); /* Orange */
  color: #fff;
}

.range-low-medium {
  background-color: rgb(244, 194, 110); /* Light Orange */
  color: #000;
}

.range-medium {
  background-color: rgb(210, 210, 140); /* Yellow */
  color: #000;
}

.range-medium-high {
  background-color: rgb(140, 210, 180); /* Light Green */
  color: #000;
}

.range-high {
  background-color: rgb(101, 244, 153); /* Green */
  color: #fff;
}