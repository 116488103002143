.custom-markdown p {
    margin-top: 4px;    /* Reduce the top margin */
    margin-bottom: 4px; /* Reduce the bottom margin */
} 

.custom-markdown h4 {
    margin-top: 4px;    /* Reduce the top margin */
    margin-bottom: 4px; /* Reduce the bottom margin */
} 

.custom-markdown ul {
    margin-top: 2px;    /* Reduce the top margin */
    margin-bottom: 2px; /* Reduce the bottom margin */
} 