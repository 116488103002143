.group-row {
    background-color: #f9f9f9 !important; /* Light grey background */
    cursor: default !important; /* Ensure the pointer cursor applies */
}

.group-row:hover .MuiButton-root {
    cursor: pointer !important; /* Override the cursor for buttons within group rows */
}

.latest-report-row {
    background-color: #ecf5ff !important; /* Light blue background */
}
