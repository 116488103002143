@keyframes pulse-blue {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #2979ff;
    }
    100% {
        background-color: transparent;
    }
}

.pulse-animation {
    animation: pulse-blue 1s ease-in-out;
    animation-iteration-count: 2;
}